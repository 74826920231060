<template>
  <div class="spin_wheel_section">
    <div ref="spinPin" class="spin_pin">
      <SpinPin :color="campaignData.spinner_pin_color" />
    </div>
    <div
      width="438"
      height="582"
      class="the_wheel"
      align="center"
      valign="center"
    >
      <div ref="wheelInner" class="wheel_inner">
        <canvas id="canvas" width="434" height="434">
          <p style="color: white" align="center">
            Sorry, your browser doesn't support canvas. Please try again.
          </p>
        </canvas>
      </div>
    </div>
    <div class="spin_now_btn">
      <button
        id="spin_button"
        v-bind:style="step2ButtonStyle"
        @click="onSpinPress"
      >
        {{ campaignData?.spinner_button_text ?? "Spin Now!" }}
      </button>
    </div>
  </div>
</template>

<script>
import Luckywheel from "@/assets/js/luckywheel.js";
import { SpinPin } from "@/components/utilities/icons.js";
export default {
  name: "Wheel",
  components: {
    SpinPin,
  },
  computed: {
    spinPin: function () {
      return this.$refs.spinPin;
    },
    wheelInner: function () {
      return this.$refs.wheelInner;
    },
    campaignData: function () {
      const { campaignData } = this.$store.getters;
      return campaignData;
    },
    step2ButtonStyle: function () {
      return this.$store.getters.step2ButtonStyle;
    },
  },
  data() {
    return {
      wheelData: {
        segmentsList: [],
        OuterRadius: "212",
        InnerRadius: "60",
        WheelStrokeColor: "#FFFFFF",
        WheelStrokeWidth: "3",
        WheelTextColor: "#FFFFFF",
        WheelTextSize: "20",
        GameOverText:
          "THANK YOU FOR PLAYING SPIN2WIN WHEEL. COME AND PLAY AGAIN SOON!",
        IntroText: "YOU HAVE TO CLICK SPIN BUTTON TO WIN IT!",
        backgroundOption: {
          type: "image",
          value: "background_12ec1d70a7c72c01f48a1d4416074129.jpg",
        },
        centerWheelImage: "wheelcenter_b8aba481386aabee47bb588032606137.png",
        WheelPinImage: "wheelcenter_42b535480fd11de938ec249bf2b60678.png",
        enablediscountbar: "on",
        countdowntime: "15",
        position: "screen_bottom",
        showdesktop: "on",
        desktopintent: "on",
        desktopinterval: "on",
        DesktopIntervaltext: "17",
        showmobile: "off",
        mobileintent: "on",
        mobileinterval: "on",
        MobileIntervaltext: "19",
        cookiedays: "5",
      },
      globlefuncgeneral: {
        gameover_text: "",
        wheel_text_color: "",
        wheel_stroke_color: "",
        segmentsForSpin: [],
        arrSegmentsList: [],
        theWheel: [],
        actual_JSON: [],
        arrsp: [],
        strarr: "",
        cookidy: 0,
        wheelPower: 0,
        wheelSpinning: false,
      },
      audio: new Audio(require("@/assets/spinsound.mp3")),
    };
  },
  methods: {
    rotate(view, degrees) {
      const style = {
        "-webkit-transform": "rotate(" + degrees + "deg)",
        "-moz-transform": "rotate(" + degrees + "deg)",
        "-ms-transform": "rotate(" + degrees + "deg)",
        transform: "rotate(" + degrees + "deg)",
      };
      if (view) {
        for (const [key, value] of Object.entries(style)) {
          view.style[key] = value;
        }
      }
    },
    alertWinResult(selectedSegment) {
      // Do basic alert of the segment text. You would probably want to do something more interesting with this information.
      //alert(selectedSegment.text + " = " + gameover_text);
      this.rotate(this.spinPin, 0);
      // $("#spinWinResult").text(globlefuncgeneral.gameover_text);
      // $(".power_controls").hide();

      this.$emit("onResult", selectedSegment);
      // $("#displayprice").html(response);
      // $('#spin').modal('show');
      // $(".reset_btn").show();
    },
    onSpinPress() {
      // Ensure that spinning can't be clicked again while already running.
      if (this.globlefuncgeneral.wheelSpinning == false) {
        // Based on the power level selected adjust the number of spins for the wheel, the more times is has
        // to rotate with the duration of the animation the quicker the wheel spins.
        if (this.globlefuncgeneral.wheelPower == 1) {
          this.globlefuncgeneral.theWheel.animation.spins = 3;
        } else if (this.globlefuncgeneral.wheelPower == 2) {
          this.globlefuncgeneral.theWheel.animation.spins = 8;
        } else if (this.globlefuncgeneral.wheelPower == 3) {
          this.globlefuncgeneral.theWheel.animation.spins = 15;
        }

        var c = this.globlefuncgeneral.arrsp.splice(
          (this.globlefuncgeneral.arrsp.length * Math.random()) | 0,
          1
        )[0];
        this.globlefuncgeneral.theWheel.animation.stopAngle =
          this.globlefuncgeneral.theWheel.getRandomForSegment(c);
        if (this.globlefuncgeneral.arrsp.length == 0) {
          this.globlefuncgeneral.arrsp =
            this.globlefuncgeneral.strarr.split("|");
          //   c = arrsp.splice(arrsp.length * Math.random() | 0, 1)[0] ;
        }

        // Begin the spin animation by calling startAnimation on the wheel object.
        this.globlefuncgeneral.theWheel.startAnimation();

        // Set to true so that power can't be changed and spin button re-enabled during
        // the current animation. The user will have to reset before spinning again.
        this.globlefuncgeneral.wheelSpinning = true;
      }
    },
    animafter() {
      this.rotate(this.spinPin, -10);
    },
    playSpinSound() {
      this.rotate(this.spinPin, -20);
      this.audio.pause();
      this.audio.currentTime = 0;
      this.audio.play();
    },
  },
  mounted() {
    for (let spnr of this.campaignData.spinner) {
      var segment_data = {
        txtDisplayText: spnr.title,
        txtResultText: spnr.prize,
        txtBackgroundColor: spnr.background_color,
        ddlGravity: spnr.weight_multipel,
        hdnGravityPerc: spnr.weight,
        wheelTextColor: spnr.text_color,
        PrizeId: spnr.encrypt_id,
        PrizeImage: spnr.prize_second_image,
        PrizeValue: spnr.prize_value,
        PrizeDescription: spnr.prize_description,
        PrizeCurrency: spnr.currency,
        PrizePosition: spnr.position,
        PrizeWeight: spnr.weight,
        PrizeUrl: spnr.prize_url,
        DiscountCode: spnr.discount_code,
        SponsorLogo: spnr.sponsor_logo,
        DiscountExpiryDatetimeLabel: spnr.discount_expiry_datetime_label,
        DiscountExpiryDatetime: spnr.discount_expiry_datetime,
      };
      this.wheelData.segmentsList.push(segment_data);
    }
    this.globlefuncgeneral.actual_JSON = this.wheelData;
    var segList = this.globlefuncgeneral.actual_JSON.segmentsList;
    this.globlefuncgeneral.cookidy =
      this.globlefuncgeneral.actual_JSON.cookiedays;

    // Set Background Image, Pointer Image, Wheel Center Image
    let centerWheelImage;
    if (this.campaignData) {
      centerWheelImage = this.campaignData.spinner_center_image;
    } else {
      centerWheelImage = require(`@/assets/img/lucky-wheel/${this.globlefuncgeneral.actual_JSON.centerWheelImage}`);
    }

    if (centerWheelImage != "" && this.wheelInner) {
      this.wheelInner.style.backgroundImage = `url(${centerWheelImage})`;
    }

    var segno = 1;
    for (let i = 0; i < segList.length; i++) {
      this.globlefuncgeneral.segmentsForSpin.push(segList[i].txtDisplayText);
      var v = Number(segList[i].hdnGravityPerc);
      var rang = v / 10;
      var cntrec = Math.ceil(rang);
      for (var p = 0; p < cntrec; p++) {
        this.globlefuncgeneral.arrsp.push(segno);
      }
      this.globlefuncgeneral.arrSegmentsList.push({
        fillStyle: segList[i].txtBackgroundColor,
        text: segList[i].txtDisplayText,
        winResult: segList[i].txtResultText,
        isCouponExist: segList[i].IsCouponCode,
        CouponCode: segList[i].CouponCode,
        wheelTextColor: segList[i].wheelTextColor,
        PrizeId: segList[i].PrizeId,
        PrizeImage: segList[i].PrizeImage,
        PrizeValue: segList[i].PrizeValue,
        PrizeDescription: segList[i].PrizeDescription,
        PrizeCurrency: segList[i].PrizeCurrency,
        PrizePosition: segList[i].PrizePosition,
        PrizeWeight: segList[i].PrizeWeight,
        PrizeUrl: segList[i].PrizeUrl,
        DiscountCode: segList[i].DiscountCode,
        SponsorLogo: segList[i].SponsorLogo,
        DiscountExpiryDatetimeLabel: segList[i].DiscountExpiryDatetimeLabel,
        DiscountExpiryDatetime: segList[i].DiscountExpiryDatetime,
      });
      segno = segno + 1;
    }
    this.globlefuncgeneral.strarr = this.globlefuncgeneral.arrsp.join("|");
    setTimeout(() => {
      if (this.globlefuncgeneral.arrSegmentsList.length > 0) {
        const font_size =
          this.globlefuncgeneral.actual_JSON.WheelTextSize != null &&
          this.globlefuncgeneral.actual_JSON.WheelTextSize != "" &&
          this.globlefuncgeneral.actual_JSON.WheelTextSize != undefined
            ? this.globlefuncgeneral.actual_JSON.WheelTextSize
            : 50;
        this.globlefuncgeneral.gameover_text =
          this.globlefuncgeneral.actual_JSON.GameOverText != null &&
          this.globlefuncgeneral.actual_JSON.GameOverText != "" &&
          this.globlefuncgeneral.actual_JSON.GameOverText != undefined
            ? this.globlefuncgeneral.actual_JSON.GameOverText
            : "Thanks";
        this.globlefuncgeneral.wheel_text_color =
          this.globlefuncgeneral.actual_JSON.WheelTextColor != null &&
          this.globlefuncgeneral.actual_JSON.WheelTextColor != "" &&
          this.globlefuncgeneral.actual_JSON.WheelTextColor != undefined
            ? this.globlefuncgeneral.actual_JSON.WheelTextColor
            : "#000000";
        this.globlefuncgeneral.wheel_stroke_color =
          this.globlefuncgeneral.actual_JSON.WheelStrokeColor != null &&
          this.globlefuncgeneral.actual_JSON.WheelStrokeColor != "" &&
          this.globlefuncgeneral.actual_JSON.WheelStrokeColor != undefined
            ? this.globlefuncgeneral.actual_JSON.WheelStrokeColor
            : "#000000";
        const wheel_stroke_width =
          this.globlefuncgeneral.actual_JSON.WheelStrokeWidth != null &&
          this.globlefuncgeneral.actual_JSON.WheelStrokeWidth != "" &&
          this.globlefuncgeneral.actual_JSON.WheelStrokeWidth != undefined
            ? this.globlefuncgeneral.actual_JSON.WheelStrokeWidth
            : "3";
        const wheel_inner_radious =
          this.globlefuncgeneral.actual_JSON.InnerRadius != null &&
          this.globlefuncgeneral.actual_JSON.InnerRadius != "" &&
          this.globlefuncgeneral.actual_JSON.InnerRadius != undefined
            ? this.globlefuncgeneral.actual_JSON.InnerRadius
            : "212";
        const wheel_outer_radious =
          this.globlefuncgeneral.actual_JSON.OuterRadius != null &&
          this.globlefuncgeneral.actual_JSON.OuterRadius != "" &&
          this.globlefuncgeneral.actual_JSON.OuterRadius != undefined
            ? this.globlefuncgeneral.actual_JSON.OuterRadius
            : "60";

        this.globlefuncgeneral.theWheel = new Luckywheel({
          numSegments: this.globlefuncgeneral.arrSegmentsList.length, // Specify number of segments.
          outerRadius: parseInt(wheel_outer_radious), // Set radius to so wheel fits the background.
          innerRadius: parseInt(wheel_inner_radious), // Set inner radius to make wheel hollow.
          textFontSize: font_size, // Set font size accordingly.
          textMargin: 0, // Take out default margin.
          segments: this.globlefuncgeneral.arrSegmentsList,
          wheelTextColor: this.globlefuncgeneral.wheel_text_color, // Set font size accordingly.
          WheelStrokeColor: this.globlefuncgeneral.wheel_stroke_color,
          WheelStrokeWidth: wheel_stroke_width,
          // Define spin to stop animation.
          animation: {
            type: "spinToStop",
            duration: 5,
            spins: 8,
            callbackFinished: this.alertWinResult,
            callbackAfter: this.animafter,
            callbackSound: this.playSpinSound, // Function to call when the tick sound is to be triggered.
          },
        });
      }
    }, 100);
  },
};
</script>