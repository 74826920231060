<template>
  <transition name="modal-fade">
    <div class="modal-backdrop-class">
      <div class="modal-cls" role="dialog" aria-describedby="modalDescription">
        <section class="modal-body-cls" id="modalDescription">
          <slot name="body">
            this is the default body!
          </slot>
          <button v-if="showClose" type="button" class="btn-close-cls" @click="close" aria-label="Close modal">
            <i class="fa fa-times"/>
          </button>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import VueTypes from 'vue-types'
export default {
  name: "Modal",
  props: {
    showClose: VueTypes.bool.def(false),
  },
  methods: {
    close() {
      this.$emit("close");
    }
  },
}
</script>

<style scoped>
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease;
}

.modal-backdrop-class {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .5);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 999;
}

.modal-cls {
  overflow-x: visible;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}

.modal-body-cls {
  position: relative;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-close-cls {
  position: absolute;
  top: 20px;
  right: 15px;
  border: none;
  font-size: 20px;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: bold;
  color: #a4a4a4;
  background: transparent;
}


@media only screen
  and (min-device-width: 310px)
  and (max-device-width: 850px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) {
  .modal-backdrop-class {
    align-items: baseline;
  }
}

/* @media only screen
  and (min-device-width: 310px)
  and (max-device-width: 850px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) {
  .modal-backdrop-class {
    display: block;
  }
  .modal-cls {
    padding: 0;
  }
} */

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (device-aspect-ratio: 40/71)
  and (orientation: portrait) {
  .modal-cls {
    padding-top: 80px;
    overflow-x: hidden;
  }
}

@media only screen 
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
  .modal-cls {
    padding-top: 300px;
  }
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
  .modal-cls {
    padding-top: 150px;
  }
}

@media (max-width: 422px) {
  .modal-backdrop-class {
    display: block;
  }
  .modal-cls {
    padding: 0;
  }
}
</style>