<template>
  <div
    ref="mainContainer"
    :class="`${campaignData.theme_type === 'without_card' ? 'without_card container-class' : 'container-class'}`"
    :style="{ backgroundImage: `url(${campaignData.bg_image})` }"
  >
    <div class="d-flex flex-column sub-container">
      <!-- <power-by
        v-if="
          campaignData.powered_by_text &&
          campaignData.theme_type === 'verticle'
        "
        :campaignData="campaignData"
      /> -->
      <div
        class="
          card-view
          pd-5
          d-flex
          flex-direction-column
          speen_wheel_container
          text-center
          speen_wheel_container_vertical
        "
        v-bind:style="step2CardStyle"
        v-if="campaignData.theme_type === 'verticle'"
      >
        <div
          v-if="campaignData.spinner_title || campaignData.spinner_description"
          class="slinner_content"
        >
          <h2 v-bind:style="step2HeaderStyle" class="title-class">
            {{ campaignData.spinner_title }}
          </h2>
          <p v-bind:style="step2TextStyle" class="msg-txt pb-5">
            {{ campaignData.spinner_description }}
          </p>
        </div>
        <div class="spinner_wheel mt-30">
          <LuckyWheel :wheelData="campaignData" @onResult="onResult" />
        </div>
      </div>
      
      <div
        class="
          card-view
          pd-5
          d-flex
          flex-direction-column
          speen_wheel_container
          text-center
          speen_wheel_container_vertical
        "
        v-bind:style="step2CardStyle"
        v-else-if="campaignData.theme_type === 'without_card'"
      >
        <img
          :src="campaignData.campaign_image"
          style=""
          class="theme3_top_img"
        />
        <div
          v-if="campaignData.spinner_title || campaignData.spinner_description"
          class="slinner_content"
        >
          <h2 v-bind:style="step2HeaderStyle" class="title-class">
            {{ campaignData.spinner_title }}
          </h2>
          <p v-bind:style="step2TextStyle" class="msg-txt pb-5">
            {{ campaignData.spinner_description }}
          </p>
        </div>
        <div class="spinner_wheel mt-30">
          <LuckyWheel :wheelData="campaignData" @onResult="onResult" />
        </div>
      </div>
      <div
        class="card-view pd-5 d-flex speen_wheel_container"
        v-bind:style="step2CardStyle"
        v-else
      >
        <div
          v-if="campaignData.spinner_title || campaignData.spinner_description"
          class="slinner_content"
        >
          <h2 v-bind:style="step2HeaderStyle" class="title-class">
            {{ campaignData.spinner_title }}
          </h2>
          <p v-bind:style="step2TextStyle" class="msg-txt pb-5">
            {{ campaignData.spinner_description }}
          </p>
        </div>
        <div class="spinner_wheel">
          <LuckyWheel :wheelData="campaignData" @onResult="onResult" />
        </div>
      </div>
      <power-by
        v-if="
          campaignData.powered_by_text
          //&& campaignData.theme_type !== 'verticle'
        "
        :campaignData="campaignData"
      />
    </div>
    <Modal v-show="showResultModal" @close="onResultClose" :showClose="true">
      <template v-slot:body>
        <div class="spin_result">
          <div class="modal-content">
            <div class="modal-body">
              <div ref="displayprice" id="displayprice"></div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import LuckyWheel from "@/components/lucky-wheel/LuckyWheel.vue";
import PowerBy from "@/components/powerby/PowerBy.vue";
import Modal from "@/components/frontmodals/Modal.vue";
import { mapActions } from "vuex";

export default {
  name: "Page2",
  components: {
    Modal,
    PowerBy,
    LuckyWheel,
  },
  computed: {
    displayprice: function () {
      return this.$refs.displayprice;
    },
    isWinner: function () {
      return this.$store.getters.isWinner;
    },
    campaignData: function () {
      return this.$store.getters.campaignData;
    },
    isSubscribed: function () {
      return this.$store.getters.isSubscribed;
    },
    isResultPageVisited: function () {
      return this.$store.getters.isResultPageVisited;
    },
    participantId: function () {
      return this.$store.getters.participantId;
    },
    step2HeaderStyle: function () {
      return this.$store.getters.step2HeaderStyle;
    },
    step2TextStyle: function () {
      return this.$store.getters.step2TextStyle;
    },
    step2CardStyle: function () {
      return this.$store.getters.step2CardStyle;
    },
  },
  data() {
    return {
      showResultModal: false,
      resultText: "",
    };
  },
  methods: {
    ...mapActions(["declareResult", "getResult"]),
    showNextModal() {
      this.$store.commit("setPageCount", 3);
    },
    onResult(selectedSegment) {
      // let response = "";
      // if (selectedSegment.text) {
      //   response += "<p>" + selectedSegment.text + "</p>";
      // }
      // if (selectedSegment.winResult) {
      //   response += "<p>" + selectedSegment.winResult + "</p>";
      // }

      this.setResult(selectedSegment);

      this.submitResult(
        selectedSegment.PrizeWeight !== 0 ? selectedSegment.PrizeId : ""
      );

      // this.displayprice.innerHTML = response;
      // this.resultText = response;
      // this.showResultModal = true;
    },
    onResultClose() {
      this.showResultModal = false;
      this.resultText = "";
      const { redirect_url = "" } = this.campaignData;
      if (redirect_url) {
        location.replace(redirect_url);
      } else {
        this.$router.replace({ path: `/${this.$route.params.slug}` });
      }
    },
    checkStatus() {
      if (!this.isSubscribed || this.isResultPageVisited) {
        this.$router.replace({ path: `/${this.$route.params.slug}` });
        this.$store.commit("setPageCount", 1);
        this.clearData();
      }

      if (!this.isResultPageVisited) {
        this.$store.commit("managePageVisit", true);
        this.isModalVisible = true;
      }
    },
    clearData() {
      this.$store.commit("setCampaignData", {});
      this.$store.commit("setWinnerFlag", false);
      this.$store.commit("manageSubscribeFlag", false);
    },
    async submitResult(prize_id = "") {
      const params = {
        slug: this.$route.params.slug,
        participant_id: this.participantId,
        prize_id: prize_id,
      };

      await this.declareResult(params);
      this.showNextModal();
    },
    async setResult(Result) {
      await this.getResult(Result);
    },
  },
  created() {
    this.checkStatus();
  },
};
</script>